import { UserRole_Enum } from '~/hasura/__generated__';
import { getUser } from './localStorage';

export const prefixUrlWithRole = (
  url: string,
  role: UserRole_Enum | undefined = undefined,
): string => {
  const usedRole = role || getUser()?.role;
  switch (usedRole) {
    case UserRole_Enum.Provider:
      return `/sp${url}`;
    case UserRole_Enum.BuildingManager:
      return `/bm${url}`;
    case UserRole_Enum.User:
      return `/user${url}`;
    default:
      return `${url}`;
  }
};
